
import { defineComponent } from 'vue';
import useTenantConfiguration from './useTenantConfiguration';
export default defineComponent({
  setup() {
    const { tenantConfiguration, DEPLOYMENT } = useTenantConfiguration();
    return {
      tenantConfiguration,
      DEPLOYMENT,
    };
  },
});
