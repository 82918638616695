import { ref } from 'vue';
import useTenant from '../tenant-management/useTenant';

import { TenantConfiguration, Secret, DeploymentMethod } from './types';
const DEPLOYMENT = new Map<DeploymentMethod, string>([
  [DeploymentMethod.Container, '容器'],
  [DeploymentMethod.VitrualMachine, '虚拟机'],
]);

export default function useTenantConfiguration() {
  const tenantSecret = ref<Secret>({
    id: '****************************',
    key: '****************************',
  });
  const tenantConfiguration = ref<Partial<TenantConfiguration>>({
    ...tenantSecret.value,
  });

  const getCfg = async () => {
    try {
      const { tenant, fetchTenantInfo } = useTenant();

      if (!tenant.value) {
        await fetchTenantInfo();
      }
      tenantConfiguration.value = {
        ...tenantConfiguration.value,
        deploymentMethod: tenant.value?.deploymentMethod,
      };
    } catch (error) {
      console.log(error);
    }
  };
  getCfg();
  return {
    tenantConfiguration,
    DEPLOYMENT,
  };
}
