// Generated by https://quicktype.io

export interface Secret {
  id: string;
  key: string;
}

export const enum DeploymentMethod {
  Container = 1,
  VitrualMachine,
}
export type DeploymentMethodMap = Record<DeploymentMethod, string>;

export type Configuration = Secret & {
  deployment: DeploymentMethod;
};

export interface TenantInfo {
  id: number;
  updateTime: string;
  createTime: string;
  createUser: null;
  updateUser: null;
  isDelete: boolean;
  version: number;
  name: string;
  tenantEngAbbr: string;
  nameShort: string;
  industryId: string;
  natureId: string;
  scaleId: string;
  license: string;
  addr: string;
  addrDetail: string;
  intro: string;
  licenseUrl: string;
  logoUrl: string;
  host: string;
  status: number;
  deploymentMethod: number;
  contact: Contact;
  sourceUrl: SourceURL;
  manager: Manager;
}

export interface Contact {
  id: number;
  updateTime: string;
  createTime: string;
  createUser: null;
  updateUser: null;
  version: number;
  tenantId: number;
  name: string;
  phone: string;
  IDCard: string;
  email: string;
  frontPhoto: string;
  reversePhoto: string;
}

export interface Manager {
  userId: number;
  id: number;
  deptId: number;
  userName: string;
  displayName: string;
  status: number;
  tenantId: number;
  deptName: null;
  phoneNumber: string;
  primaryMail: string;
  gender: number;
  position: null;
  admin: boolean;
  activate: boolean;
}

export interface SourceURL {
  licenseUrl: string;
  logoUrl: string;
  frontPhoto: string;
  reversePhoto: string;
}
export type TenantConfiguration = Secret & {
  deploymentMethod: DeploymentMethod | undefined;
};
